import { Box, Flex } from 'grid-styled'
import * as React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'
import Map from '../components/Map/Map'

import CTA from '../components/CTA'
import Header from '../components/Header'
import FAQs from '../components/FAQs'
import Contact from '../components/Contact'
import Hero from '../components/Hero'
import Button from '../components/Button'
import styled from 'styled-components'

const Content = styled.div`
  max-width: 750px;
  width: 90%;
  margin: 80px auto 50px;

  h2 {
    font-weight: 400;
    margin-bottom: 1em;
  }

  span {
    color: ${(props) => props.theme.colors.primary};
    font-size: 20px;
    display: inline-block;
    margin-bottom: 1em;
  }
`

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>Contact - Plastic Surgeon Dr Graham Sellars</title>
      <meta
        name="description"
        content="Plastic surgery in Sydney - Dr Graham Sellars is a plastic and cosmetic surgeon in Sydney, NSW providing breast implants, breast enlargement, rhinoplasty nose jobs and eyelid surgery."
      />
    </Helmet>
    <Box style={{ textAlign: 'center', marginTop: 150, paddingBottom: 30 }}>
      <h1 style={{ fontWeight: 400 }}>Contact Dr. Sellars Today</h1>
      <Contact
        style={{ paddingTop: '3rem', textAlign: 'left' }}
        noBorder={true}
      />
    </Box>
    <Hero bgImage={data.womanWaterfall.childImageSharp} height="600px">
      <h1>Do you have questions?</h1>
      <p>
        Call 02 9480 8577 or please complete the enquiry form to contact us
        today and find out how we can help.
      </p>
      <Flex>
        <Button color={'#fff'} small={true} outline={true}>
          Contact Us
        </Button>
      </Flex>
    </Hero>
    <Content>
      <h2>The San Clinic - Sydney</h2>
      <span>Dr Sellars’ medical suite is located at The San Clinic.</span>

      <p>
        The San Clinic is the specialist medical centre within the grounds of
        the Sydney Adventist Hospital. There is parking directly under the San
        Clinic building which makes it easy to access the suites without having
        to go into the general hospital parking.
      </p>

      <p>
        Many patients come to us on the personal recommendation of others, so we
        really appreciate how important it is to look after you. Our rooms are
        modern and smartly decorated with the latest medical and audio equipment
        to ensure your experience is as relaxed as possible.
      </p>

      <p>
        From the moment you first visit us for a consultation, through surgery
        preparation, the surgery itself and the after care you receive, Dr.
        Sellars’ and his team is focused solely on you.
      </p>

      <span>Suite 507 San Clinic, 185 Fox Valley Road, Wahroonga, NSW, 2076</span>

      <p>
        Dr. Sellars has performed thousands of Plastic and Reconstructive
        Surgical procedures and has a strong focus on Breast Surgery and
        Facial Cosmetic Surgery.
      </p>
    </Content>
    <Map />
    <FAQs />
  </Layout>
)

export const query = graphql`
  query ContactPageQuery {
    womanWaterfall: file(relativePath: { eq: "contact.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
