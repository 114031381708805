import React from 'react'
import GoogleMap from 'google-map-react'
import styled from 'styled-components'

import styles from './mapStyles.json'

const Container = styled.section`
  width: 100%;
  height: 500px;
  overflow: hidden;
  margin: 50px 0;
  background-color: #e5e3df; /* Google maps default bg colour */
`

const map = {
  defaultZoom: 10,
  coordinates: {
    lat: -33.7328221,
    lng: 151.0997644
  },
  center: {
    lat: -33.7328221,
    lng: 151.0997644
  }
}

const Marker = styled.div`
  width: 30px;
  height: 30px;
  border: 2px solid #fff;

  position: absolute;
  transform-origin: center center;
  transform: translate(-50%, -50%);
  will-change: transform;
  background: ${props => props.theme.colors.primary};
  border-radius: 100%;
`

const Map = () => (
  <Container>
    <GoogleMap
      bootstrapURLKeys={{ key: ['AIzaSyB4LtY1YDW9QKwHBbDrY4_TYhUjDsYnZjY'] }}
      defaultCenter={map.center}
      defaultZoom={map.defaultZoom}
      options={{ styles }}
    >
      <Marker
        alt=""
        src={<Marker />}
        lat={map.coordinates.lat}
        lng={map.coordinates.lng}
      />
    </GoogleMap>
  </Container>
)

export default Map
